.logoWrapper{
    max-width: 300px;
    height: auto;

}


.logo{
    height: auto;
    width: 100%;
}

.Addresstext{
    text-align: right;
}

.Divider{
    margin: 20px 0px;
    padding: 0;
    border-top : 5px solid #00B5F0 !important;
    border-radius: 50px;
    
}

.whiteText{
    color: #ffffff !important;
    font-weight: bold !important;
}