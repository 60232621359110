.book_container {
    width: 250px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgb(0 0 0 / 25%);
}

.book_img {
    width: 250px;
    height: 300px;
    object-fit: cover;
    border-radius: 10px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.book_name {
    font-size: 20px;
    font-weight: bold;
    margin: 5px 0;
    text-align: center;
}

.book_author {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
    text-align: center;
}

.book_price {
    font-size: 16px;
    font-weight: 500;
    margin: 5px 0;
    text-align: center;
}

.book_price>span {
    color: #21a700;
}

.buy_btn {
    width: 100%;
    height: 50px;
    outline: none;
    border: none;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #f4d072;
    margin-top: 10px;
}